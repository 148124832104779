import SignClient from '@walletconnect/sign-client'
import {SessionTypes, SignClientTypes} from '@walletconnect/types'
import {GetVersionResult} from '@cityofzion/neon-core/lib/rpc'
import {
  SignMessagePayload,
  SignedMessage,
  ContractInvocation,
  ContractInvocationMulti,
  Signer,
  Arg,
  RpcResponseStackItem,
  EncryptedPayload,
  InvokeResult,
  DecryptFromArrayResult,
  BuiltTransaction,
  CalculateFee,
} from '@cityofzion/neon-dappkit-types'
import EventEmitter from 'events'
import TypedEventEmitter from 'typed-emitter'
import {
  NetworkType,
  Method,
  CoreEvents,
} from '@/libs/wallet-connect-sdk-core/types'
import {WcSdkError} from '@/libs/wallet-connect-sdk-core/WcSdkError'

export class WcSdk {
  signClient: SignClient

  public readonly emitter = new EventEmitter() as TypedEventEmitter<CoreEvents>
  private contextualMessage: string | undefined = undefined

  constructor(client: SignClient) {
    this.signClient = client
  }

  private _session: SessionTypes.Struct | null = null

  get session() {
    return this._session
  }

  set session(session: SessionTypes.Struct | null) {
    this._session = session
    this.emitter.emit('session', session)
  }

  isConnected(): boolean {
    return !!this.session
  }

  getChainId(namespace: string): NetworkType | string | null {
    const info = this.getAccountInfo(namespace)
    return info && `${info[0]}:${info[1]}`
  }

  getAccountAddress(namespace: string): string | null {
    const info = this.getAccountInfo(namespace)
    return info && info[2]
  }

  loadSession(): SessionTypes.Struct | null {
    if (this.signClient.session.values[0]) {
      this.session = this.signClient.session.values[0]
    }

    return this.session
  }

  async createConnection(
    network: NetworkType,
    methods: Method[],
    namespace: string
  ): Promise<{
    uri?: string
    approval: () => Promise<SessionTypes.Struct>
  }> {
    console.log('Creating connection')

    console.log(methods)

    if (methods.length === 0) {
      throw new Error('At least one method is required')
    }

    console.log('Creating connection')

    const {approval, uri} = await this.signClient.connect({
      requiredNamespaces: {
        [namespace]: {
          chains: [network],
          methods,
          events: [],
        },
      },
    })

    console.log('Connection created')

    const approvalWrapper = async () => {
      const session = await approval()
      this.session = session
      return session
    }

    console.log('approvalWrapper')

    const compatibilityVersion = 3 // NEO3
    const uriAndWccv = `${uri}&wccv=${compatibilityVersion}`

    console.log('uriAndWccv')

    return {
      approval: approvalWrapper,
      uri: uriAndWccv,
    }
  }

  async disconnect(): Promise<void> {
    if (!this.session) return

    await this.signClient.disconnect({
      topic: this.session.topic,
      reason: {
        code: 5900,
        message: 'USER_DISCONNECTED',
      },
    })

    this.session = null
  }

  async sendRequest(
    method: Method,
    namespace: string,
    params: any
  ): Promise<string> {
    const request = {
      id: 1,
      jsonrpc: '2.0',
      method,
      params: {...params, contextualMessage: this.contextualMessage},
    }

    delete this.contextualMessage

    return await this.signClient.request({
      topic: this.session?.topic ?? '',
      chainId: this.getChainId(namespace) ?? '',
      request,
    })
  }

  private getAccountInfo(namespace: string): string[] | null {
    const accounts = this.session?.namespaces[namespace].accounts
    if (!accounts?.length) {
      return null
    }
    return accounts[0].split(':') ?? null
  }
}
