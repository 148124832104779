import {RootState, WalletAdapterState} from '@/types/store'
import {ActionContext, Module} from 'vuex'
import {AccessorHandler} from '@simpli/vuex-typescript'
import {WalletPlatform} from '@/enums/WalletPlatform'
import {WalletMutation} from '@/enums/WalletMutation'
import {$} from '@/facade'
import {NeolineAdapter} from '@/model/wallets/adapters/NeolineAdapter'
import {NeonN3WalletAdapter} from '@/model/wallets/adapters/NeonN3WalletAdapter'
import {NeolineInvocationBuilder} from '@/model/wallets/invocationsBuilder/NeolineInvocationBuilder'
import {NeonWalletInvocationBuilder} from '@/model/wallets/invocationsBuilder/NeonWalletInvocationBuilder'
import {cloneDeep} from 'lodash'
import {
  InvokeParams,
  LastWalletConnected,
  PopulateSdkStatusParams,
  WalletsImplementation,
} from '@/model/wallets/types/WalletTypes'
import {
  InvocationDoesNotExists,
  RejectedByUser,
  UnimplementedWallet,
  WalletNotConnected,
} from '@/model/wallets/types/WalletErrors'
import {NeonXWalletAdapter} from '@/model/wallets/adapters/NeonXWalletAdapter'

export type WalletAdapterContext = ActionContext<WalletAdapterState, RootState>

const walletsImplementation: WalletsImplementation = {
  n3: {
    [WalletPlatform.NEON]: {
      adapter: new NeonN3WalletAdapter(),
      invocationBuilder: new NeonWalletInvocationBuilder(),
    },
    [WalletPlatform.NEOLINE]: {
      adapter: new NeolineAdapter(),
      invocationBuilder: new NeolineInvocationBuilder(),
    },
  },
  neoX: {
    [WalletPlatform.NEON]: {
      adapter: new NeonXWalletAdapter(),
      invocationBuilder: new NeonWalletInvocationBuilder(),
    },
    [WalletPlatform.NEOLINE]: {
      adapter: new NeolineAdapter(),
      invocationBuilder: new NeolineInvocationBuilder(),
    },
  },
}

const n3LastWalletLocalStorageId: string = 'n3LastWalletConnected'
const xLastWalletLocalStorageId: string = 'xLastWalletConnected'

@AccessorHandler
export class WalletAdapterModule
  implements Module<WalletAdapterState, RootState> {
  namespaced = true

  state: WalletAdapterState = {
    n3WalletsStatus: {
      [WalletPlatform.NEON]: null,
      [WalletPlatform.NEOLINE]: null,
    },
    xWalletsStatus: {
      [WalletPlatform.NEON]: null,
      [WalletPlatform.NEOLINE]: null,
    },
    n3LastWalletConnected: null,
    n3ConnectedWalletPlatform: null,
    n3Address: null,
    n3Uri: null,
    xLastWalletConnected: null,
    xConnectedWalletPlatform: null,
    xAddress: null,
    xUri: null,
  }

  getters = {
    n3LastWalletConnected: (state: WalletAdapterState) =>
      state.n3LastWalletConnected,
    n3ConnectedWalletPlatform: (state: WalletAdapterState) =>
      state.n3ConnectedWalletPlatform,
    n3Address: (state: WalletAdapterState) => state.n3Address,
    n3Uri: (state: WalletAdapterState) => state.n3Uri,
    xLastWalletConnected: (state: WalletAdapterState) =>
      state.xLastWalletConnected,
    xConnectedWalletPlatform: (state: WalletAdapterState) =>
      state.xConnectedWalletPlatform,
    xAddress: (state: WalletAdapterState) => state.xAddress,
    xUri: (state: WalletAdapterState) => state.xUri,
  }

  actions = {
    async initN3Wallet(context: WalletAdapterContext) {
      const initPromises = Object.values(walletsImplementation.n3).map(
        wallet => {
          if (wallet) {
            return wallet.adapter.init(context)
          }

          return Promise.resolve() // Return a resolved promise
        }
      )

      await Promise.all(initPromises)

      if (
        typeof localStorage !== 'undefined' &&
        !context.state.n3Address &&
        localStorage.getItem(n3LastWalletLocalStorageId)?.trim()
      ) {
        const storage: string | null = localStorage.getItem(
          n3LastWalletLocalStorageId
        )

        if (storage?.trim()) {
          const lastWallet = JSON.parse(storage.trim()) as LastWalletConnected

          if (lastWallet) {
            context.commit(
              WalletMutation.N3_POPULATE_ADDRESS,
              lastWallet.address
            )
            context.commit(
              WalletMutation.N3_POPULATE_CONNECTED_WALLET_TYPE,
              lastWallet.walletPlatform
            )
            context.commit(
              WalletMutation.N3_POPULATE_LAST_WALLET_CONNECTED,
              lastWallet
            )
          }
        }
      }
    },

    async initNeoXWallet(context: WalletAdapterContext) {
      const initPromises = Object.values(walletsImplementation.neoX).map(
        wallet => {
          if (wallet) {
            return wallet.adapter.init(context)
          }

          return Promise.resolve() // Return a resolved promise
        }
      )

      await Promise.all(initPromises)

      if (
        typeof localStorage !== 'undefined' &&
        !context.state.xAddress &&
        localStorage.getItem(xLastWalletLocalStorageId)?.trim()
      ) {
        const storage: string | null = localStorage.getItem(
          xLastWalletLocalStorageId
        )

        if (storage?.trim()) {
          const lastWallet = JSON.parse(storage.trim()) as LastWalletConnected

          if (lastWallet) {
            context.commit(
              WalletMutation.X_POPULATE_ADDRESS,
              lastWallet.address
            )
            context.commit(
              WalletMutation.X_POPULATE_CONNECTED_WALLET_TYPE,
              lastWallet.walletPlatform
            )
            context.commit(
              WalletMutation.X_POPULATE_LAST_WALLET_CONNECTED,
              lastWallet
            )
          }
        }
      }
    },

    async connectN3Wallet(
      context: WalletAdapterContext,
      walletPlatform: WalletPlatform
    ) {
      if (context.state.n3ConnectedWalletPlatform) {
        return false
      }

      console.log('connectN3Wallet', walletPlatform)

      const wallet = walletsImplementation.n3[walletPlatform]
      if (!wallet) throw UnimplementedWallet

      console.log('wallet', wallet)

      await wallet.adapter.connect(context)

      console.log('context.state.n3Address', context.state.n3Address)

      if (context.state.n3Address) {
        /** We update Last Wallet Connected because NeoLine Hooks does
         *  not work properly, so we need to know if the user has connected
         *  before and not disconnected.
         */
        const n3LastWalletConnected: LastWalletConnected = {
          address: context.state.n3Address,
          walletPlatform: walletPlatform,
        }

        context.commit(
          WalletMutation.N3_POPULATE_LAST_WALLET_CONNECTED,
          n3LastWalletConnected
        )

        if (typeof localStorage !== 'undefined') {
          localStorage.setItem(
            n3LastWalletLocalStorageId,
            JSON.stringify({
              address: context.state.n3Address,
              walletPlatform: walletPlatform,
            })
          )
        }

        context.commit(WalletMutation.N3_POPULATE_URI, null)

        context.commit(
          WalletMutation.N3_POPULATE_CONNECTED_WALLET_TYPE,
          walletPlatform
        )

        $.modal?.close('connectWalletModal')

        $.toast.success('system.success.walletConnected')

        return true
      } else throw RejectedByUser
    },

    async connectNeoXWallet(
      context: WalletAdapterContext,
      walletPlatform: WalletPlatform
    ) {
      console.log('to aqui')

      if (context.state.xConnectedWalletPlatform) {
        return false
      }

      const wallet = walletsImplementation.neoX[walletPlatform]
      if (!wallet) throw UnimplementedWallet

      await wallet.adapter.connect(context)

      if (context.state.xAddress) {
        /** We update Last Wallet Connected because NeoLine Hooks does
         *  not work properly, so we need to know if the user has connected
         *  before and not disconnected.
         */
        const xLastWalletConnected: LastWalletConnected = {
          address: context.state.xAddress,
          walletPlatform: walletPlatform,
        }
        context.commit(
          WalletMutation.X_POPULATE_LAST_WALLET_CONNECTED,
          xLastWalletConnected
        )

        if (typeof localStorage !== 'undefined') {
          localStorage.setItem(
            xLastWalletLocalStorageId,
            JSON.stringify({
              address: context.state.xAddress,
              walletPlatform: walletPlatform,
            })
          )
        }

        context.commit(WalletMutation.X_POPULATE_URI, null)

        context.commit(
          WalletMutation.X_POPULATE_CONNECTED_WALLET_TYPE,
          walletPlatform
        )

        $.modal?.close('connectWalletModal')

        $.toast.success('system.success.walletConnected')

        return true
      } else throw RejectedByUser
    },

    async disconnectN3Wallet(context: WalletAdapterContext) {
      if (!context.state.n3ConnectedWalletPlatform) {
        return false
      }

      const wallet =
        walletsImplementation.n3[context.state.n3ConnectedWalletPlatform]

      if (!wallet) {
        return false
      }

      await wallet.adapter.disconnect()

      if (typeof localStorage !== 'undefined') {
        localStorage.removeItem(n3LastWalletLocalStorageId)
      }

      context.commit(WalletMutation.N3_POPULATE_ADDRESS, null)
      context.commit(WalletMutation.N3_POPULATE_CONNECTED_WALLET_TYPE, null)
      context.commit(WalletMutation.N3_POPULATE_LAST_WALLET_CONNECTED, null)
    },

    async disconnectNeoXWallet(context: WalletAdapterContext) {
      if (!context.state.xConnectedWalletPlatform) {
        return false
      }

      const wallet =
        walletsImplementation.neoX[context.state.xConnectedWalletPlatform]

      if (!wallet) {
        return false
      }

      await wallet.adapter.disconnect()

      if (typeof localStorage !== 'undefined') {
        localStorage.removeItem(xLastWalletLocalStorageId)
      }

      context.commit(WalletMutation.X_POPULATE_ADDRESS, null)
      context.commit(WalletMutation.X_POPULATE_CONNECTED_WALLET_TYPE, null)
      context.commit(WalletMutation.X_POPULATE_LAST_WALLET_CONNECTED, null)
    },

    async canUseN3Wallet(
      context: WalletAdapterContext,
      walletPlatform: WalletPlatform
    ) {
      if (!walletPlatform) return false

      const wallet = walletsImplementation.n3[walletPlatform]
      const walletStatus = context.state.n3WalletsStatus[walletPlatform]

      if (!wallet || !walletStatus) {
        return false
      }

      return wallet.adapter.isInstalled() && walletStatus === 'started'
    },

    async canUseNeoXWallet(
      context: WalletAdapterContext,
      walletPlatform: WalletPlatform
    ) {
      if (!walletPlatform) return false

      const wallet = walletsImplementation.neoX[walletPlatform]
      const walletStatus = context.state.xWalletsStatus[walletPlatform]

      if (!wallet || !walletStatus) {
        return false
      }

      return wallet.adapter.isInstalled() && walletStatus === 'started'
    },

    async invokeN3Wallet(
      context: WalletAdapterContext,
      multiInvoke: InvokeParams
    ) {
      if (!context.state.n3ConnectedWalletPlatform) throw WalletNotConnected

      if (!multiInvoke || !multiInvoke.method || !multiInvoke.params)
        throw InvocationDoesNotExists

      const wallet =
        walletsImplementation.n3[context.state.n3ConnectedWalletPlatform]

      const walletStatus =
        context.state.n3WalletsStatus[context.state.n3ConnectedWalletPlatform]

      if (!wallet || !wallet.invocationBuilder || walletStatus !== 'started')
        throw UnimplementedWallet

      const method = wallet.invocationBuilder[multiInvoke.method].bind(
        wallet.invocationBuilder
      )
      if (!method || typeof method !== 'function') throw InvocationDoesNotExists

      return (await wallet.adapter.invoke(
        method(multiInvoke.params as any)
      )) as string
    },

    async invokeNeoXWallet(
      context: WalletAdapterContext,
      multiInvoke: InvokeParams
    ) {
      if (!context.state.xConnectedWalletPlatform) throw WalletNotConnected

      if (!multiInvoke || !multiInvoke.method || !multiInvoke.params)
        throw InvocationDoesNotExists

      const wallet =
        walletsImplementation.neoX[context.state.xConnectedWalletPlatform]

      const walletStatus =
        context.state.n3WalletsStatus[context.state.xConnectedWalletPlatform]

      if (!wallet || !wallet.invocationBuilder || walletStatus !== 'started')
        throw UnimplementedWallet

      const method = wallet.invocationBuilder[multiInvoke.method].bind(
        wallet.invocationBuilder
      )
      if (!method || typeof method !== 'function') throw InvocationDoesNotExists

      return (await wallet.adapter.invoke(
        method(multiInvoke.params as any)
      )) as string
    },

    async executeWithConnectedN3Wallet(
      context: WalletAdapterContext,
      actionToDoAfterConnect: () => Promise<void> | void
    ) {
      const walletIsConnected: WalletPlatform | null =
        context.state.n3ConnectedWalletPlatform

      if (walletIsConnected != null) {
        await actionToDoAfterConnect()
        return
      }

      $?.modal.open('connectWalletModal', actionToDoAfterConnect)
    },

    async executeWithConnectedXWallet(
      context: WalletAdapterContext,
      actionToDoAfterConnect: () => Promise<void> | void
    ) {
      const walletIsConnected: WalletPlatform | null =
        context.state.xConnectedWalletPlatform

      if (walletIsConnected != null) {
        await actionToDoAfterConnect()
        return
      }

      $?.modal.open('connectWalletModal', actionToDoAfterConnect)
    },
  }

  mutations = {
    N3_POPULATE_LAST_WALLET_CONNECTED(
      state: WalletAdapterState,
      lastWalletConnected: LastWalletConnected | null
    ) {
      state.n3LastWalletConnected = lastWalletConnected
    },
    N3_POPULATE_CONNECTED_WALLET_TYPE(
      state: WalletAdapterState,
      connectedWalletPlatform: WalletPlatform | null
    ) {
      state.n3ConnectedWalletPlatform = connectedWalletPlatform
    },
    N3_POPULATE_SDK_STATUS(
      state: WalletAdapterState,
      params: PopulateSdkStatusParams
    ) {
      state.n3WalletsStatus = cloneDeep({
        ...state.n3WalletsStatus,
        [params.wallet]: params.status,
      })
    },
    N3_POPULATE_URI(state: WalletAdapterState, uri: string | null) {
      state.n3Uri = uri
    },
    N3_POPULATE_ADDRESS(state: WalletAdapterState, address: string | null) {
      state.n3Address = address
    },
    X_POPULATE_LAST_WALLET_CONNECTED(
      state: WalletAdapterState,
      lastWalletConnected: LastWalletConnected | null
    ) {
      state.xLastWalletConnected = lastWalletConnected
    },
    X_POPULATE_CONNECTED_WALLET_TYPE(
      state: WalletAdapterState,
      connectedWalletPlatform: WalletPlatform | null
    ) {
      state.xConnectedWalletPlatform = connectedWalletPlatform
    },
    X_POPULATE_SDK_STATUS(
      state: WalletAdapterState,
      params: PopulateSdkStatusParams
    ) {
      state.xWalletsStatus = cloneDeep({
        ...state.xWalletsStatus,
        [params.wallet]: params.status,
      })
    },
    X_POPULATE_URI(state: WalletAdapterState, uri: string | null) {
      state.xUri = uri
    },
    X_POPULATE_ADDRESS(state: WalletAdapterState, address: string | null) {
      state.xAddress = address
    },
  }
}
